























import {Component, Prop, Vue} from 'vue-property-decorator';
import {formatDate} from '@/utility/formatDate.js';

import './scss/PopupProjectEdit.scss';

@Component({
    name: 'PopupProjectEdit',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        FormProjectEditGeneral: () => import('@components/Forms/FormProjectEditGeneral.vue'),
    }
})

export default class PopupProjectEdit extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        formProjectEditGeneral: HTMLFormElement,
    }

    @Prop({}) projectData!: any;

    get projectTitle() {
        if (this.projectData) {
            return this.projectData.projectName || this.projectData.title;
        }
        return '';
    }

    get startDate() {
        return this.projectData
            ? formatDate({
                date: new Date(this.projectData.startDate),
                format: 'yyyy-mm-dd',
            })
            : '';
    }

    get endDate() {
        return this.projectData
            ? formatDate({
                date: new Date(this.projectData.endDate),
                format: 'yyyy-mm-dd',
            })
            : '';
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    sendForm() {
        this.$refs.formProjectEditGeneral.sendForm();
    }
}
